﻿/**
 * Main Navigation
 */

const mainNavigation = new nav({
    toggle: '.js-nav_toggle',
    el: '.js-mobile_nav',
    breakpoint: 1024
});

/**
 * Main Navigation Constructor
 * @param {string, selector} el Selector for the parent navigation element
 * @param {string, selector} toggle Selector for the nav toggle element
 * @param {int}              breakpoint The viewport pixel width where we transision from mobile nav to desktop. 
 *                           Equal to the last pixel where the mbile view is used
 */
function nav(config) {
    const self = this;
    self.el = document.querySelector(config.el);
    self.toggle = document.querySelector(config.toggle);
    self.toggleClose = Array.from(document.querySelectorAll('.js-nav_toggle_close'));
    self.breakpoint = config.breakpoint;
    self.isMobile = false;
    self.isActive = false;

        // Initial setup
        self.init = () => {
    if (self.toggle != null) {
            // Set the flag for mobile nav
            self.isMobile = checkMobile();

            //Check mobile on resize
            window.addEventListener('resize', function () {
                self.isMobile = checkMobile();
            });

            // Handle the nav toggle (hamburger menu)
            self.toggle.addEventListener('click', () => {
                if (self.isActive === false) {
                    self.checkSubNavActive();
                    self.setOpen();
                    self.isActive = true;
                } else {
                    self.close();
                    self.isActive = false;
                }
            });

            self.toggleClose.map((closeBtn) => {
                closeBtn.addEventListener('click', () => {
                    self.close();
                    self.isActive = false;
                });
            });

            if (self.isMobile) {
            const primaryNavLinks = Array.from(document.querySelectorAll('.js-primary'));

            primaryNavLinks.map((link) => {
                var btn = link.querySelectorAll('.js-primary-btn')[0];
                if(btn) {
                    btn.addEventListener('click', (e) => {
                        if (self.isMobile) {
                        e.preventDefault();
                        link.classList.toggle("js-primary--open");
                        btn.classList.toggle("c-nav_mobile_primary_btn_icon--open");
                        }
                    })
                }

                var hasActivePage =  link.querySelectorAll('.js-active-page').length > 0;

                if (hasActivePage) {
                    link.classList.toggle("js-primary--open");
                    btn.classList.toggle("c-nav_mobile_primary_btn_icon--open");
                }
            });

            const secondaryNavLinks = Array.from(document.querySelectorAll('.js-sublink'));
            
            secondaryNavLinks.map((link) => {
                var primaryLink = link.querySelectorAll('.c-nav__sublevel_item')[0];
                var btn = primaryLink.querySelectorAll('.js-sublink-btn')[0];
                if(btn) {
                    btn.addEventListener('click', (e) => {
                        if (self.isMobile) {
                        e.preventDefault();
                        primaryLink.classList.toggle("js-sublink--open");
                        btn.classList.toggle("c-nav_mobile_primary_btn_icon--open");
                        }
                    })
                }

                var hasActivePage =  link.querySelector('.js-active-page') != null;
                if (hasActivePage) {
                    primaryLink.classList.toggle("js-sublink--open");
                    if(btn) {
                        btn.classList.toggle("c-nav_mobile_primary_btn_icon--open");
                    }
                } 
            });
            }
         }
        };
    
    /**
     * Open the nav
     */
    self.setOpen = function () {
        // Add the nav-active class to the head
        //self.header.classList.add('nav-active');
        // Add the active class to the toggle
        self.toggle.classList.add('is-active');
        // Show the nav
        self.el.classList.add('is-active');

        self.isActive = true;
    };

    /**
     * Close the nav
     */
    self.close = function () {
        // Remove the nav-active class from the header
        //self.header.classList.remove('nav-active');
        // Add the active class to the toggle
        self.toggle.classList.remove('is-active');
        // Hide the nav
        self.el.classList.remove('is-active');

        // Reset any explicit styles (nav height etc)
        self.el.removeAttribute('style');
        // Remove any active classes on children
        Array.from(self.el.getElementsByTagName('*')).map((el) => {
            el.classList.remove('is-active');
        });

        self.isActive = false;
    };

    /**
     * Open subnav menu if a sub page is currently open
     */
    self.checkSubNavActive = function() {
        Array.from(self.el.getElementsByClassName('c-nav_mobile__sublevel')).map((subnav) => {
            var isActive = subnav.getElementsByClassName("selected").length > 0;
            if (isActive) {
                openSubnav(subnav);
            }
        });
    }
    
    // Execute init on instantiation
    self.init();

    function checkMobile() {
        if (window.innerWidth <= config.breakpoint) {
            return true;
        } else {
            return false;
        }
    }

    function closeSubnav(subnav) {
        subnav.classList.remove('is-active');
        subnav.setAttribute('aria-expanded', 'false');
    }
    function openSubnav(subnav) {
        subnav.classList.add('is-active');
        subnav.setAttribute('aria-expanded', 'true');
    }
}